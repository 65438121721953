import { RootState } from '../store/root-reducer';
import { getActionHandlers } from '../store';

type ActionHandlers = ReturnType<typeof getActionHandlers>;
type MemberHandlers = ActionHandlers['memberHandlers'];
type BlogPostsHandlers = ActionHandlers['blogPostsHandlers'];
type SiteHandlers = ActionHandlers['siteHandlers'];
type UIHandlers = ActionHandlers['uiHandlers'];

export interface ControllerProps {
  instance: string;
  member: RootState['member'];
  defaultBIParams: Object;
  site: RootState['site'];
  badges: RootState['badges'];
  blogPosts: RootState['blogPosts'];
  ui: RootState['ui'];
  appSettings: RootState['appSettings'];
  memberHandlers: MemberHandlers;
  blogPostsHandlers: BlogPostsHandlers;
  siteHandlers: SiteHandlers;
  uiHandlers: UIHandlers;
}

export enum WarmupDataKey {
  InitialData = 'initialData',
}
