export enum Experiment {
  EnableSectionsTabInMobileAS = 'specs.membersAboutOOI.enableSectionsMobileAS',
  EnableOOIInEditor = 'specs.membersArea.framelessEditorProfilePage',
  EnableModalResizing = 'specs.membersAboutOOI.aboutModalResize',
  UseWarmupData = 'specs.membersAboutOOI.useWarmupData',
  MobilePostBorderWidth = 'specs.membersAboutOOI.mobileBorderWidth',
  HeaderSettings = 'specs.membersArea.aboutHeaderSettings',
  EnablePostCounter = 'specs.membersAboutOOI.postCounterInTitle',
  RenderSEOTags = 'specs.membersAboutOOI.RenderSEOTags',
  PostCardPadding = 'specs.membersAboutOOI.postCardSidePadding',
}
